import EmberRouter from '@ember/routing/router';
import config from 'portal/config/environment';

export default class Router extends EmberRouter {
    location = config.locationType;
    rootURL = config.rootURL;
}

Router.map(function () {
    if (config.environment !== 'production') {
        this.route('freestyle');
    }

    this.route('login');
    this.route('callback');

    this.route('protected', { path: '/', resetNamespace: true }, function () {
        this.route('surveys');
        this.route('verify-email-address');
        this.route('account-creation-flow', function () {
            this.route('add-entity');
            this.route('communication-preferences');
            this.route('completed');
            this.route('edit-entity', { path: 'edit-entity/:legal_entity_id' });
            this.route('home');
            this.route('my-entities');
            this.route('my-team');
            this.route('personal-details');
            this.route('team-overview');
            this.route('user-type');
        });

        this.route('deals', function () {
            this.route('in-progress', { path: '/' });
            this.route('complete');
        });

        this.route('deal', { path: '/deals/:deal_id' }, function () {
            this.route('index', { path: '/' }, function () {
                this.route('my-deal', { path: '/' });
                this.route('to-do');
            });
            this.route('onboarding', function () {
                this.route('welcome');
                this.route('titleholder-preference');
                this.route('individual-titleholder');
                this.route('individual-multiselect-titleholder');
                this.route('select-entity');
                this.route('entity-titleholder');
                this.route('property-information');
                this.route('lender-tax-address-info');
                this.route('cpc-notary-intermediary');
                this.route('review');
                this.route('personal-information');
                this.route('refinance-pacer');
                this.route('refinance-credit-lines');
                this.route('edit-entity', { path: 'edit-entity/:legal_entity_id' });
                this.route('confirm-entity-details');
                this.route('seller-closing-preferences');
                this.route('seller-property-information');
                this.route('seller-titleholder-info');
            });
            this.route('action-item-completion', { path: 'action-item-completion/:action_item_id' });
            this.route('closing-request-completion');
            this.route('buyer-closing-preferences-and-coverages');
            this.route('buyer-titleholder-and-financing-information');
            this.route('buyer-wire-instructions', { path: 'buyer-wire-instructions/:action_item_id' });
            this.route('closing-request');
            this.route('payoff-authorization', { path: 'payoff-authorization/:action_item_id' });
            this.route('provide-rental-information');
            this.route('settlement-statement-review');
            this.route('titleholder-information', function () {
                this.route('complete');
                this.route('confirm-entity-details');
                this.route('edit-entity', { path: 'edit-entity/:legal_entity_id' });
                this.route('edit-individual', { path: 'edit-individual/:legal_individual_id' });
                this.route('multiselect-individual-titleholder');
                this.route('new-entity');
                this.route('new-individual');
                this.route('preference');
                this.route('select-entity');
                this.route('select-titleholder');
            });
            this.route('earnest-money', function() {
                this.route('selection');
                this.route('portal');
                this.route('wire');
                this.route('check');
                this.route('funding-source-selection');
                this.route('funding-source-creation');
                this.route('portal-transfer', { path: 'portal-transfer/:dwolla_funding_source_id' });
            });
            this.route('commitment-review');
        });

        this.route('invites', { path: 'invites/:invite_id' });
        this.route('team-invites', { path: 'team-invites/:team_invite_id' });
        this.route('account');

        this.route('my-account', function () {
            this.route('add-entity');
            this.route('add-legal-individual');
            this.route('communication-preferences');
            this.route('earnest-transactions');
            this.route('edit-entity', { path: 'edit-entity/:legal_entity_id' });
            this.route('edit-legal-individual', { path: 'edit-legal-individual/:legal_individual_id' });
            this.route('my-entities');
            this.route('my-team');
            this.route('my-titleholders');
            this.route('personal-details');
            this.route('team-overview');
        });

        this.route('intake', function() {
            this.route('start-deal');
            this.route('buyer-seller', { path: 'buyer-seller/:intake_user_id' });
            this.route('purchase-contract', { path: 'purchase-contract/:intake_user_id' });
            this.route('deal-request-confirmation', { path: 'deal-request-confirmation/:create_deal_request_id' });
        });

        this.route('plaid', function() {
            this.route('oauth-landing');
        });
    });

    this.route('s', { path: '/s/:slug' });
    this.route('email-verification-complete');
    this.route('index-html', { path: 'index.html' });
    this.route('termsandconditions');

    this.route('catch-unrecognized', { path: '*' });
});
